<template>
  <div class="wrap">
    <div class="tips">
      <p>合同说明</p>
      <p>为了保证店铺能够正常采货，请完成合同的签署；</p>
      <p>
        合同的签署需要在当前页面(合同信息-合同列表中)，或者在水羊直供小程序里面进入【个人中心-店铺信息】里面填写完合同信息后，系统会发送电子合同的短信到您的注册手机号码，点击短信链接完成合同的签署即可，签署完合同，合同列表就会显示合同签署的信息；
      </p>
    </div>
    <el-table
      :data="distributorContractList"
      style="width: 100%"
      max-height="600"
      border
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column label="合同名称" prop="contractName"></el-table-column>
      <el-table-column label="合同类型" prop="typeName" width="100"></el-table-column>
      <el-table-column label="授权渠道" prop="authChannelNames">
        <template slot-scope="scope">{{ scope.row.authChannelNames.join() }}</template>
      </el-table-column>
      <el-table-column label="公司名称" prop="company">
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.contractParticipantList" :key="index">
            <span v-if="scope.row.contractParticipantList.length > 1">主体{{ Number(index + 1 ) }}：</span>
            <span>{{ item.company }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="合同有效期" prop="typeName">
        <template slot-scope="scope"
          >{{ scope.row.startDate | parseTime('{y}-{m}-{d}') }}至{{
            scope.row.endDate | parseTime('{y}-{m}-{d}')
          }}</template
        >
      </el-table-column>
      <el-table-column label="合同状态" prop="statusName" width="100"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="getContract(scope.row.contractUrl)"
            type="text"
            v-if="scope.row.signStatus === 'FINISH' && scope.row.contractUrl"
            >查看合同</el-button
          >
          <el-button
            @click="getContract(scope.row.shortSignUrl)"
            type="text"
            v-if="scope.row.signStatus === 'WAITING'"
            >签署合同</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getContractInfo } from '@/api/shop';
export default {
  name: 'BasicInfo',
  data() {
    return {
      contractInfoVO: {}, // 合同信息
      shopName: '', // 店铺名称
      distributorContractList: [], // 合同管理列表
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
      headerRowStyle: { color: '#333333' }
    };
  },
  mounted() {
    this.getContractInfo();
  },
  methods: {
    getContractInfo() {
      getContractInfo(this.contractQuery).then((res) => {
        this.contractInfoVO = res.data.contractInfoVO || {};
        this.shopName = res.data.shopName || '';
        this.distributorContractList = res.data.distributorContractList || [];
      });
    },
    getContract(url) {
      window.open(url);
    }
  }
};
</script>
<style lang="scss" scoped>
@import './bargain-info';
</style>
