<!-- 续签 -->
<template>
  <div class="resign-box">
    <div class="resign-box-p">
      <label class="resign-box-p-label">续签品牌：</label>{{ resignObj.name }}
    </div>
    <div class="resign-box-p">
      <label class="resign-box-p-label">可续签时间：</label
      >{{ resignObj.licenseDuration }}{{ resignObj.licenseDurationType }}
    </div>
    <div class="resign-box-p">
      <label class="resign-box-p-label">初签合同：</label>
      <ul class="init-contract">
        <li v-if="resignObj.contractId">{{ resignObj.contractStatusName }}</li>
        <li v-if="resignObj.spliceDesc">{{ resignObj.spliceDesc }}</li>
      </ul>
    </div>
    <div class="resign-box-p resign-box-p-flex">
      <label class="resign-box-p-label">续签合同：</label>
      <el-select
        v-model="contractInfoMainKey"
        placeholder="请选择"
        clearable
        v-if="contractForPcList.length"
      >
        <el-option
          v-for="item in contractForPcList"
          :key="item.contractInfoMainKey"
          :label="item.spliceDesc"
          :value="item.contractInfoMainKey"
        ></el-option>
      </el-select>
      <span v-else-if="hasContract" style="color: red;">暂无可用合同，请联系分销经理</span>
      <span v-else>当前暂无可用合同，请上传承诺函</span>
    </div>
    <div class="resign-box-p resign-box-p-flex">
      <label class="resign-box-p-label">承诺函：</label>
      <upload
        :accept="accept"
        is-private
        is-valid
        :limit="1"
        :max-capacity="10"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
      >
        <el-button size="small" type="primary">上传文件</el-button>
        <el-button type="text" @click="downloadPromiseTemplate"
          >点击下载承诺函模板</el-button
        >
        <p class="tip">只能上传{{ acceptFormat }}文件，且不超过10MB</p>
      </upload>
    </div>
  </div>
</template>

<script>
import {
  brandLicenseListSpecialBrand,
  distributorLicensePreResign,
  distributorLicenseResign
} from '@/api/brandauthorization';
import Upload from '@/components/Upload/index.vue';
export default {
  components: { Upload },
  data() {
    return {
      resignObj: {},
      checkContractStatus: false,
      contractId: '',
      distributorContractInfoId: '',
      contractInfoMainKey: '',
      izFrameworkAgreement: '',
      accept: '.doc,.docx,.jpeg,.jpg,.png,.pdf',
      commitmentLetter: '',
      specialBrandList: []
    };
  },
  watch: {
    contractInfoMainKey(val) {
      const currentContract = this.contractForPcList.find(item => item.contractInfoMainKey === val) || {};
      const { id = '', distributorContractInfoId = '', izFrameworkAgreement = '' } = currentContract;
      this.contractId = id;
      this.distributorContractInfoId = distributorContractInfoId;
      this.izFrameworkAgreement = izFrameworkAgreement;
    },
  },
  computed: {
    // 合同下列表
    contractForPcList() {
      const { contractForPcList = [] } = this.resignObj;
      return contractForPcList.map(item => {
        return {
          ...item,
          contractInfoMainKey: `${item.id}${item.distributorContractInfoId}`,
        };
      });
    },
    acceptFormat() {
      return this.accept
        .replace(/\./g, '')
        .split(',')
        .join('/');
    },
    // 是否需要上传承诺函
    hasCommitmentLetter() {
      const { brandId } = this.YDialogInitData;
      const { commitmentLetter = '' } = this.resignObj;
      return (
        this.specialBrandList.includes(brandId) || this.izFrameworkAgreement === '0' || !!commitmentLetter
      );
    },
    // 是否需要续签合同
    hasContract() {
      const { brandId } = this.YDialogInitData;
      return (
        this.specialBrandList.includes(brandId) || this.resignObj.tradeType === 'GENERAL_TRADE'
      );
    }
  },
  created() {
    this.getSpecialAuthorizedBrand();
    this.getDistributorLicensePreResign();
  },
  methods: {
    // 获取续签信息
    getDistributorLicensePreResign() {
      const { id, brandName } = this.YDialogInitData;
      distributorLicensePreResign(id).then(res => {
        const {
          commitmentLetter = '',
          licenseDuration,
          licenseDurationType,
          contractStatus,
          contractStatusName,
          reSignContract,
          id: powerId,
          contractId = '',
          distributorContractInfoId = '',
          spliceDesc,
          contractForPcList = [],
          tradeType,
        } = res.data;
        // this.resignShow = true;
        this.resignObj = {
          id,
          commitmentLetter,
          powerId, // 授权书id
          contractId, // 初签合同id
          distributorContractInfoId,
          name: brandName,
          spliceDesc,
          contractForPcList,
          tradeType,
          contractStatus,
          contractStatusName,
          reSignContract,
          licenseDuration,
          licenseDurationType: this.valueChange(licenseDurationType)
        };
        if (contractStatus === 'USE') {
          this.contractInfoMainKey = `${contractId}${distributorContractInfoId}`;
          this.contractId = contractId;
          this.distributorContractInfoId = distributorContractInfoId;
        }
      });
    },
    // 获取特殊授权品牌
    getSpecialAuthorizedBrand() {
      brandLicenseListSpecialBrand().then(res => {
        this.specialBrandList = res.data || [];
      });
    },
    // 给父弹出框确定按钮
    getChildData() {
      const {
        commitmentLetter,
        hasContract,
        contractId = '',
        distributorContractInfoId = ''
      } = this;
      const { id } = this.resignObj;
      const parameter = { id };
      // 续签合同必填
      if (hasContract && !contractId) {
        this.$message.error('请选择续签合同');
        return false;
      }
      // 承诺函必填
      if (this.hasCommitmentLetter && !commitmentLetter) {
        this.$message.error('请上传承诺函');
        return false;
      }
      if (contractId) {
        parameter.contractId = contractId;
      }
      if (distributorContractInfoId) {
        parameter.distributorContractInfoId = distributorContractInfoId;
      }
      if (commitmentLetter) {
        parameter.commitmentLetter = commitmentLetter;
      }
      return new Promise((resolve, reject) => {
        distributorLicenseResign(parameter)
          .then(res => {
            this.$alert(
              '您已成功提交续签申请，如需查看申请进程，可在申请记录里面查看!'
            );
            return resolve(res);
          })
          .catch(err => {
            if (err.data && err.data.code === '700') {
              this.$alert(err.data.msg);
            }
            return reject(false);
          });
      });
    },
    valueChange(val) {
      switch (val) {
        case 'YEAR': {
          return '年';
        }
        case 'MONTH': {
          return '个月';
        }
        case 'DAY': {
          return '天';
        }
      }
    },
    // 下载承诺函模板
    downloadPromiseTemplate() {
      window.location.href = `${process.env.VUE_APP_MUSHUROOMFILEURL}/static/file/soyoung-zg/template/销售承诺函模板.docx`;
    },
    // 上传成功
    handleSuccess(res) {
      this.commitmentLetter = res.data || '';
    },
    // 清除文件
    handleRemove() {
      this.commitmentLetter = '';
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  .el-select {
    width: 100%;
  }
  .el-upload {
    text-align: left;
  }
}
.tip {
  margin-top: 8px;
  font-size: 12px;
  color: #999;
}
.resign-box-p {
  margin-bottom: 16px;
  font-size: 15px;
  display: flex;
  &-label {
    color: #999999;
    font-size: 14px;
    display: block;
    width: 100px;
    flex-shrink: 0;
  }
  &-flex {
    display: flex;
    align-items: flex-start;
  }
  &-content {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    &-company {
      margin-bottom: 10px;
    }
  }
}
.init-contract {
  li {
    font-size: 12px;
    color: #999;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
